var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"my-0"},[_c('Alertize'),_c('v-layout',{attrs:{"column":""}},[_c('v-form',{ref:"form",attrs:{"justify":"center","align":"center","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-text-field',{ref:"id",staticClass:"label-fixed disabled",attrs:{"hint":_vm.$t('users.fields.id'),"placeholder":_vm.$t('users.fields.id'),"label":_vm.$t('users.fields.id')+'*',"disabled":""},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-text-field',{ref:"email",staticClass:"label-fixed disabled",attrs:{"hint":"E-mail","placeholder":"E-mail","label":"E-mail*","disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-text-field',{ref:"name",staticClass:"label-fixed",attrs:{"rules":[
									_vm.getRules.isRequired,
									_vm.getRules.isMinLength,
									_vm.getRules.isMaxLength,
								],"hint":_vm.$t('users.fields.name'),"placeholder":_vm.$t('users.fields.name'),"label":_vm.$t('users.fields.name'),"counter":"255"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-text-field',{ref:"name",staticClass:"label-fixed",attrs:{"rules":[
									_vm.getRules.isRequired,
									_vm.getRules.isMinLength,
									_vm.getRules.isMaxLength,
								],"hint":_vm.$t('users.fields.last_name'),"placeholder":_vm.$t('users.fields.last_name'),"label":_vm.$t('users.fields.last_name'),"counter":"255"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-autocomplete',{ref:"role_id",staticClass:"label-fixed",attrs:{"rules":[
									_vm.getRules.isRequired,
									_vm.getRules.isNumber,
								],"hint":_vm.$t('users.fields.role'),"items":_vm.getRoles,"item-text":"description","item-value":"id","label":_vm.$t('users.fields.role')+'*',"placeholder":_vm.$t('users.fields.role')},model:{value:(_vm.role_id),callback:function ($$v) {_vm.role_id=$$v},expression:"role_id"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('CardSwitch',{staticClass:"ml-2 mt-3",attrs:{"reference":"active","label":`${_vm.active ? 'Active' : 'Inactive'}`,"color":"success"},on:{"change":_vm.toggleStatus},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1)],1),_c('v-divider',{staticClass:"ma-4"}),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-card-text',{staticClass:"secondary--text info-message",attrs:{"align":"start"}},[_vm._v(" "+_vm._s(_vm.$t("common.labels.mandatory"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-row',{attrs:{"align":"end","justify":"end"}},[_c('v-btn',{staticClass:"mx-2 px-8",attrs:{"type":"submit","rounded":"","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),_c('v-btn',{staticClass:"mx-2 px-8",attrs:{"type":"button","rounded":"","color":"secondary"},on:{"click":_vm.redirectTo}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }